@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");

:root {
  --blue: rgb(7, 47, 132);
  --lighterBlue: rgb(7, 55, 158);
  --faintBlue: rgb(199, 215, 249);
  --green: rgb(0, 137, 43);
  --lighterGreen: rgb(46, 158, 81);
}

/* setup and layout */

* {
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
}

@media (min-width: 640px) {
  * {
    font-size: 16px;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body > div {
  height: 100%;
  position: relative;
  overflow: hidden;
}

h1 {
  font-size: 48px;
}

/* button */

.button {
  background-color: var(--blue);
  color: white;
  margin: 0;
  border: 0;
  height: 40px;
  border-radius: 20px;
  padding: 0px 20px;
  transition: opacity 0.2s ease-in-out;
}

.button--disabled {
  opacity: 0.5;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--faintBlue);
}

.button:not(.button--disabled):hover {
  background-color: var(--lighterBlue);
}

.button--success {
  background-color: var(--green);
}

.button--success:hover {
  background-color: var(--lighterGreen);
}

/* bubble */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  1000% {
    opacity: 1;
  }
}

.bubbles {
  animation: fadeIn 0.5s ease-in-out;
}

.bubble {
  align-items: center;
  border-radius: 50%;
  border: 3px solid;
  color: white;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 14px;
  text-align: center;
  transition: all 0.4s;
}

.bubble--large {
  font-size: 18px;
  padding: 16px;
}

@media (min-width: 640px) {
  .bubble--large {
    font-size: 24px;
  }
}

/* input */

.input {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #cecece;
  min-width: 180px;
  padding: 0 16px;
  margin: 0;
}

.input:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--faintBlue);
  border-color: var(--blue);
}

/* input-with-button */

.input-with-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 320px;
  margin: auto;
}

.input-with-button .input {
  border-right-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
}

.input-with-button .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-with-button__error {
  position: absolute;
  bottom: -20px;
  font-size: 13px;
  color: red;
}

/* banner */

.banner {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
  padding: 20px 20px;
}

.banner__content {
  max-width: 800px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner__title,
.banner__subtitle {
  font-size: 20px;
  margin: 0;
  line-height: 1.6;
}

.banner__title {
  font-size: 16px;
  font-weight: bold;
}

.banner__subtitle {
  font-size: 12px;
  color: #cacaca;
}

@media (min-width: 640px) {
  .banner__title {
    font-size: 24px;
  }

  .banner__subtitle {
    font-size: 18px;
  }
}

/* footer */

.footer {
  position: absolute;
  bottom: 12px;
  padding: 6px 20px 0 20px;
  left: 0px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

/* footer-link */

.footer-link {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  opacity: 0.7;
  width: fit-content;
}

.footer-link:hover {
  opacity: 1;
}

.footer-link svg {
  margin-left: 6px;
  width: 26px;
  height: 26px;
}

.footer-link p {
  font-size: 14px;
  margin: 0;
}

/* signup */

.signup {
  text-align: center;
  margin-bottom: 60px;
}

.signup__title {
  margin-top: 0;
  margin-bottom: 6px;
}

@media (min-width: 640px) {
  .signup {
    margin-bottom: 20px;
  }
}

/* toggle */

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle__button {
  color: black;
  padding: 4px 8px;
  border: 1px solid var(--blue);
  cursor: pointer;
}

.toggle__button:focus {
  outline: none;
  border-color: var(--blue);
  box-shadow: 0 0 0 3px var(--faintBlue);
}

.toggle__button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.toggle__button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.toggle__button--selected {
  color: white;
  background-color: var(--blue);
  border-color: transparent;
}
